import React from 'react';
import 'react-barcode-scanner/polyfill';
import Box from '@mui/joy/Box';
import HeroLeft01 from './landing/HeroLeft';
import { IconButton, useColorScheme } from '@mui/joy';

import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import { useDetectPwa } from './pwa/useDetectPwa';

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return null;
  }
  return (
      <IconButton
          id="toggle-mode"
          size="lg"
          variant="soft"
          color="neutral"
          onClick={() => {
            if (mode === 'light') {
              setMode('dark');
            } else {
              setMode('light');
            }
          }}
          sx={{
            position: 'fixed',
            zIndex: 999,
            top: '1rem',
            right: '1rem',
            borderRadius: '50%',
            boxShadow: 'sm',
          }}
      >
        {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
  );
}

function App() {
  const {checking, pwa} = useDetectPwa();
  if (checking) {
    return null;
  }
  if (pwa) {
    document.location.href = '/app';
    return;
  }
  return (
      <>
        <ColorSchemeToggle />
        <Box
            sx={{
              height: '100vh',
              overflowY: 'scroll',
              scrollSnapType: 'y mandatory',
              '& > div': {
                scrollSnapAlign: 'start',
              },
            }}
        >
          <HeroLeft01 />
        </Box>
      </>
  
  );
}

export default App;
