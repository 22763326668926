import React, {useState} from 'react';
import Page from '../layout/Page';
import usePrintTasks from '../data/usePrintTasks';
import PrintTaskItem from './PrintTaskItem';
import Stack from '@mui/joy/Stack';
import IconButton from '@mui/joy/IconButton';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CircleRoundedIcon from '@mui/icons-material/CircleOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVertRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import MenuButton from '@mui/joy/MenuButton';
import useUser from '../data/useUser';
import {useColorScheme} from '@mui/joy/styles';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeIcon from '@mui/icons-material/LightMode';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import Divider from '@mui/joy/Divider';
import {PrintOutlined} from '@mui/icons-material';
import Button from '@mui/joy/Button';
import usePrintSessionMutation from '../data/usePrintSessionMutation';
import useRemoveTaskMutation from '../data/useRemoveTaskMutation';
import SelectPrinterDialog from "../selectPrinter/SelectPrinterDialog";
import Typography from '@mui/joy/Typography';
import AlertSnackbar from '../components/AlertSnackbar';

const Main = () => {
  const printTasks = usePrintTasks() || [];
  const removePrintTaskMutation = useRemoveTaskMutation();
  const printSessionMutation = usePrintSessionMutation();
  const user = useUser();
  const { mode, setMode } = useColorScheme();
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [tasksToRemove, setTasksToRemove] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [selectPrinterOpen, setSelectPrinterOpen] = useState(false);
  
  const printingTasks = printTasks.data?.filter?.(x => x.jobs?.length > 0) || [];
  const allSelected = selectedTasks.length && selectedTasks.length === printTasks.data?.length - printingTasks.length - tasksToRemove.length;
  
  const handleSelectTask = (task) => () => {
    if (task.jobs.length > 0 || tasksToRemove.includes(task)) {
      return;
    }
    const newArr = selectedTasks.includes(task) ? selectedTasks.filter(x => x !== task) : [...selectedTasks, task];
    setSelectedTasks(newArr);
  }
  
  const removeTasks = (tasks) => {
    setSelectedTasks(selectedTasks.filter(x => !tasks.includes(x)));
    setTasksToRemove([...tasksToRemove, ...tasks]);
  }
  
  const handleRemove = (task) => (e) => {
    e.stopPropagation();
    removeTasks([task]);
    removePrintTaskMutation.mutate(task.id);
  }
  
  const selectAll = () => {
    if (allSelected) {
      setSelectedTasks([]);
      return;
    }
    const toAdd = [];
    printTasks.data?.forEach(x => {
      if (!x.jobs.length && !tasksToRemove.includes(x) && !selectedTasks.includes(x)) {
        toAdd.push(x);
      }
    });
    setSelectedTasks([...selectedTasks, ...toAdd]);
  }
  
  const handlePrintClicked = () => {
    if (selectedTasks.length === 0) {
      setErrorMsg("Выберите хотя бы одну задачу");
      return;
    }
    setSelectPrinterOpen(true);
  }
  
  const print = (selectedPrinter) => {
    printSessionMutation.mutate({
      printerName: selectedPrinter.name,
      taskIds: selectedTasks.map(x => x.id)
    });
    removeTasks(selectedTasks)
    setSelectPrinterOpen(false);
  }
  
  const handleModalClose = () => {
    setSelectPrinterOpen(false);
  }
  let message;
  if (printTasks.isLoading && !printTasks.error) {
    message = 'Загружаем задачи...';
  }
  if (!printTasks.isLoading && printTasks?.data?.length < 1) {
    message = 'Нет активных задач';
  }
  return (
      <Page
          title="Infiniprint"
          leftItem={
            <IconButton onClick={selectAll} variant="plain" >
              { allSelected
                  ? <CheckCircleOutlinedIcon/>
                  : <CircleRoundedIcon />
              }
            </IconButton>
          }
          rightItem={
            <Dropdown>
              <MenuButton slots={{ root: IconButton }}>
                <MoreVertIcon />
              </MenuButton>

              <Menu placement="bottom-end">
                <MenuItem>Пользователь: {user.data?.username}</MenuItem>
                <Divider/>
                <MenuItem>
                  Тема:
                  <ToggleButtonGroup
                      value={mode}
                      onChange={(event, newValue) => {
                        setMode(newValue);
                      }}
                      sx={[{width: '100%'}]}
                  >
                    <IconButton value="dark">
                      <DarkModeRoundedIcon />
                    </IconButton>
                    <IconButton value="light">
                      <LightModeIcon />
                    </IconButton>
                  </ToggleButtonGroup>
                </MenuItem>
                <MenuItem onClick={() => {
                  document.location.href = "/app/logout";
                }}><LogoutIcon/> Выйти</MenuItem>
              </Menu>
            </Dropdown>
          }
      >
        <Button
            onClick={handlePrintClicked}
            size="lg"
            sx={{
              position: 'fixed',
              borderRadius: 14,
              bottom: 20,
              right: 20,
              zIndex: 1000,
              width: 50,
              height: 50
            }}>
          <PrintOutlined size="lg"/>
        </Button>
        {message && <Typography>{message}</Typography>}
        {!printTasks.isLoading && !printTasks.error && (
            <Stack spacing={2} sx={{ my: 1, paddingBottom: 10 }}>
              { printTasks.data.map((x) => (
                  <PrintTaskItem
                      key={x.id}
                      selected={selectedTasks.includes(x)}
                      disabled={tasksToRemove.includes(x)}
                      task={x}
                      onRemove={handleRemove(x)}
                      onClick={handleSelectTask(x)}/>
              ))
              }
            </Stack>
        )}
        <AlertSnackbar msg={errorMsg} onClose={() => setErrorMsg(null)}/>

        <SelectPrinterDialog
            open={selectPrinterOpen}
            onClose={handleModalClose}
            onSelect={print}
        />

      </Page>
  );
};

Main.propTypes = {
  
};

export default Main;