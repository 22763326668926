import React, {useCallback, useState} from 'react';
import {Modal, ModalClose, ModalDialog} from '@mui/joy';
import Barcode from "../barcode/Barcode";
import Button from "@mui/joy/Button";
import usePrinters from './usePrinters';
import AlertSnackbar from '../components/AlertSnackbar';
import Stack from '@mui/joy/Stack';

const SelectPrinterDialog = ({open, onClose, onSelect}) => {
  const printers = usePrinters();
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [msg, setMsg] = useState(null);

  const handlePrintClick = () => {
    onSelect(selectedPrinter);
  }

  const printersData = printers.data;
  const handleBarcoreCapture = useCallback((barcodeData) => {
    const printer = printersData.find(x => x.name === barcodeData);
    if (!printer) {
      setMsg("Принтер не найден в системе");
    } else {
      setMsg(`Найден принтер ${printer.name}`);
      setSelectedPrinter(printer);
    }
  }, [printersData]);

  const handlePrinterListClicked = () => {
    setMsg("В будущей версии");
  }

  const handleBarcodeClick = () => {
    // Для тестирования
    // handleBarcoreCapture("nowhere")
  }

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <ModalDialog>
          <ModalClose
            variant="plain"
            sx={{m: 1}}
          />
          <Stack spacing={2}>
            <Barcode
              onCapture={handleBarcoreCapture}
              title="Наведите камеру на QR-код принтера"
              onClick={handleBarcodeClick}
            />
            <Stack spacing={1}>
              {selectedPrinter && (
                <Button onClick={handlePrintClick}>
                  Печатать на {selectedPrinter.name}
                </Button>
              )}
              <Button onClick={handlePrinterListClicked} color="neutral">
                Выбрать принтер из списка
              </Button>
            </Stack>
          </Stack>
        </ModalDialog>
      </Modal>
      <AlertSnackbar msg={msg} onClose={() => setMsg(null)}/>
    </>
  )
}

export default SelectPrinterDialog;